/* eslint-disable @typescript-eslint/no-explicit-any */
import { Account } from '../interfaces/accountsDataInterface'
import {
  IdConfigMgtSvcClient,
  UserTenantMgtSvcClient,
  UserMgtSvcClientv3
} from '@jarvis/web-stratus-client'

export const getAccounts = async (
  authStack,
  authProvider,
  orgSelector,
  userResourceId: string,
  permissionSet
): Promise<Array<Account>> => {
  const accountClient = new UserTenantMgtSvcClient(
    authStack,
    authProvider?.createOrgedAuthProvider?.()
  )
  const tenantResourceId = orgSelector?.getOrgTenantId()

  const accountsData: Array<Account> = []

  try {
    const usrListResponse = await accountClient.getUserWithTenantForSP(
      tenantResourceId,
      userResourceId
    )
    if (usrListResponse.status === 200) {
      usrListResponse.data.resourceList
        .filter(
          (item) => item.userTenantDetail.tenantResourceId !== item.user?.idpId
        )
        .map((item) => {
          const account: Account = {
            email: item.user.email.value,
            familyName: item.user.familyName,
            givenName: item.user.givenName,
            resourceId: item.user.resourceId,
            roleLabel: '',
            roleName: item.userTenantDetail.tenantType,
            rolePermId: item.userTenantDetail.rolePermId,
            state: item.userTenantDetail.state,
            tenantResourceId: item.userTenantDetail.tenantResourceId,
            phoneNumber: item.user.phoneNumber
              ? item.user.phoneNumber.value
              : ''
          }
          return account
        })
        .forEach((item) => {
          accountsData.push(item)
        })
    }
    accountsData.forEach(async (account) => {
      const permSet = permissionSet.find(
        (perm) => perm.rolePermId === account.rolePermId
      )
      if (permSet) {
        account.roleLabel = permSet.label
      }
    })
  } catch (err) {
    console.log('Error loading account With Tenant List', err)
  }
  return accountsData
}

// Organization profile info for Account details page
export const getOrganizationProfile = async (
  authStack,
  authProvider,
  orgSelector,
  userResourceId: string
): Promise<any> => {
  const accountClient = new UserTenantMgtSvcClient(
    authStack,
    authProvider?.createOrgedAuthProvider?.()
  )
  const tenantResourceId = orgSelector.getOrgTenantId()
  let usrListResponse
  try {
    usrListResponse = await accountClient.getUserWithTenantForSP(
      tenantResourceId,
      userResourceId
    )
  } catch (e) {
    console.log('Error loading account With Tenant List', e)
  }
  return usrListResponse
}

// User Personal info for Account details page
export const getUserPersonalInfo = async (
  authStack,
  authProvider,
  userResourceId: string
): Promise<any> => {
  const accountClient = new UserTenantMgtSvcClient(
    authStack,
    authProvider?.createOrgedAuthProvider?.()
  )
  const userInfo = await accountClient.getUserPersonalDetails(userResourceId)
  return userInfo
}

// Role permid for SP
export const getRolePermID = async (authStack, authProvider): Promise<any> => {
  const userMgtClient = new UserTenantMgtSvcClient(
    authStack,
    authProvider?.createOrgedAuthProvider?.()
  )
  const activeUser = await userMgtClient.getCurrentActiveUser()
  return activeUser
}

//For Logged in User Email
export const getEmail = async (authStack, authProvider): Promise<any> => {
  const userMgtClient = new UserMgtSvcClientv3(
    authStack,
    authProvider?.createOrgedAuthProvider?.()
  )
  const activeUser = await userMgtClient.getCurrentActiveUser()
  return activeUser
}

export const getIsTechnician = async (
  authStack,
  authProvider,
  permissionSet
) => {
  const userClient = new UserTenantMgtSvcClient(
    authStack,
    authProvider?.createOrgedAuthProvider?.()
  )
  let isTechnician
  await userClient
    .getCurrentActiveUser()
    .then(({ status, data }) => {
      if (status === 200) {
        const techPermId = permissionSet.filter(
          (permId) => permId.label == 'Technician'
        )
        data.rolePermId === techPermId[0].rolePermId
          ? (isTechnician = true)
          : (isTechnician = false)
      } else {
        isTechnician = false
        return isTechnician
      }
    })
    .catch(() => {
      isTechnician = false
      return isTechnician
    })
  return isTechnician
}

//Fetching Config List
export const fetchConfigList = async (
  authStack,
  authProvider
): Promise<any> => {
  const idConfigMgtClient = new IdConfigMgtSvcClient(
    authStack,
    authProvider?.createOrgedAuthProvider?.()
  )
  const response = await idConfigMgtClient.getConfigs()
  return response
}

//Fetch Config Details
export const fetchConfig = async (
  authStack,
  authProvider,
  uuid
): Promise<any> => {
  const idConfigMgtClient = new IdConfigMgtSvcClient(
    authStack,
    authProvider?.createOrgedAuthProvider?.()
  )
  const response = await idConfigMgtClient.getConfig(uuid)
  return response
}
