// eslint-disable-next-line no-restricted-imports
import { TableI18n } from '@veneer/core'
import { useMemo } from 'react'
import useShellRootContext from '../contexts/ShellRoot/useShellRootContext'

const Retrievei18nItems = (): TableI18n => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {}
  const retrieveValue = useMemo(() => {
    return {
      actionButton: '',
      cancelButton: t('accounts-management.accounts.buttons.cancel', 'Cancel'),
      clearAllFilters: '',
      columnOptions: t(
        'accounts-management.accounts.columnOptions',
        'column Options'
      ),
      columnResizeTooltip: '',
      deselectAllItems: '',
      downButton: '',
      itemSelected: '',
      itemsSelected: '',
      noItems: 'No Items Found',
      resetToDefault: t(
        'accounts-management.accounts.resetToDefault',
        'Reset To Default'
      ),
      saveButton: t('accounts-management.accounts.buttons.save', 'Save'),
      selectAllItems: '',
      selectAllPageItems: '',
      sortedAscending: '',
      sortedDescending: '',
      upButton: '',
      sortByAscending: '',
      sortByAscendingActive: '',
      sortByDescending: '',
      sortByDescendingActive: ''
    }
  }, [t])
  return retrieveValue
}

export default Retrievei18nItems
