/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { Table, TableSortBy, ToastProvider } from '@veneer/core'
import { Account } from 'src/interfaces/accountsDataInterface'
import { getl10n } from '../../../utils/localizationMap'
import {
  AccountName,
  AccountStatus,
  InviteProfile,
  NoITemsContainer,
  VeneerSpace2
} from '../styles'
import { ErrorWidget } from '@jarvis/react-portal-addons'
import {
  filter,
  sort,
  mergeData,
  jsonParse
} from '../../../utils/commonMethods'
import Retrievei18nItems from '../../../utils/Retrievei18nItems'
import {
  AccountConstants,
  accountPreferences,
  defaultpageSizeOptions,
  defaultOrderColumn
} from '../../../utils/constants'
import useShellRootContext from '../../../contexts/ShellRoot/useShellRootContext'
type ColumnIndexTypes = 'visible' | 'hidden' | 'none'

type accountListColumnstype = {
  id: string
  label: string
  index?: ColumnIndexTypes
  sortable?: boolean
}
const AccountsList = ({
  hasError,
  accounts,
  searchItem,
  openAccountDetails,
  actionArea,
  isLoading,
  searchItems,
  readPermForDetails,
  permissionSet,
  mockAccountsData
}) => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {}
  const createAccountsList = (accountData: Account[]) => {
    return accountData.map((account, index) => ({
      ...account,

      fullName: `${account.givenName || ''} ${account.familyName || ''}`,
      name: (
        <>
          {readPermForDetails && (
            <AccountName
              data-testid="account-name"
              onClick={() => openAccountDetails(account.resourceId)}
            >
              {`${account.givenName || '-'} ${account.familyName || ''}`}
            </AccountName>
          )}
          {!readPermForDetails && `${account.givenName} ${account.familyName}`}
        </>
      ),
      email: account.email,
      role: account.roleLabel,
      tablestatus: (
        <AccountStatus data-testid="account-status">
          <div className={account.state.toLowerCase()}></div>
          <span className="accounts-user-state">
            {t(getl10n(account.state), account.state)}
          </span>
        </AccountStatus>
      ),
      assignedto: 'CustomerName & 10 more',
      uid: index
    }))
  }

  const accountListColumns: Array<accountListColumnstype> = [
    {
      id: 'name',
      label: t('accounts-management.accounts.userName', 'User Name')
    },
    {
      id: 'email',
      label: t('accounts-management.accounts.emailAddress', 'Email Address')
    },
    {
      id: 'tablestatus',
      label: t('accounts-management.accounts.status', 'Status')
    },
    {
      id: 'role',
      label: t('accounts-management.accounts.role', 'Role')
    },
    // Hiding this table until the API is available
    // {
    //   id: 'assignedto',
    //   label: 'ASSIGNED TO'
    // },
    {
      id: 'uid',
      label: 'uid',
      index: 'hidden'
    }
  ]
  const [currentPage, setCurrentPage] = useState<number>(
    AccountConstants.currentPage
  )
  const AccountTableColumns = 'accountListColumns'
  const [accountData, setAccountData] = useState<Account[]>(
    mockAccountsData ? mockAccountsData : []
  )
  const [accountPageData, setAccountPageData] = useState<Account[]>([])
  const [totalItems, setTotalItems] = useState<number>(accounts.length)
  const [pageSize, setPageSize] = useState<number>(AccountConstants.pageSize)
  const [order, setOrder] = useState(
    jsonParse(sessionStorage.getItem(AccountTableColumns), defaultOrderColumn)
  )
  const [orderBy, setOrderBy] = useState<string>(AccountConstants.name)
  const [orderType, setOrderType] = useState<string>(AccountConstants.ascending)

  const customNoItems = useMemo(() => {
    if (hasError) {
      return (
        <NoITemsContainer data-testid="error-customers-list">
          <ErrorWidget
            onRetry={openAccountDetails}
            message={t(
              'accounts-management.unableToloadData',
              'Unable to Load Data'
            )}
            labelRetry={t('accounts-management.retry', 'Retry')}
          />
        </NoITemsContainer>
      )
    } else if (searchItem !== '') {
      return (
        <NoITemsContainer data-testid="error-empty-profiles-list">
          <ErrorWidget
            message={t(
              'accounts-management.accounts.noResultFound',
              'No Results Found'
            )}
          />
          <VeneerSpace2 />
          <p
            className="invalid-search-label"
            data-testid="invalid-search-label"
          >
            {t(
              'accounts-management.accounts.searchNoResultBlock',
              'Try selecting other filters or adjusting your search keywords'
            )}
          </p>
          <VeneerSpace2 />
        </NoITemsContainer>
      )
    } else {
      return (
        <NoITemsContainer data-testid="error-profiles">
          <ErrorWidget
            message={t(
              'accounts-management.accounts.noProfiles',
              'No Profiles Found'
            )}
          />
          <VeneerSpace2 />
          <InviteProfile data-testid="invite-profile-label">
            {t(
              'accounts-management.accounts.inviteProfiles',
              'Invite Profiles for business.'
            )}
          </InviteProfile>
          <VeneerSpace2 />
        </NoITemsContainer>
      )
    }
  }, [hasError, openAccountDetails, searchItem, t])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handlePageSizeChange = (event, option) => {
    event.preventDefault()
    setPageSize(option.value)
  }

  const handleSort = (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    { id, type }: TableSortBy
  ) => {
    if (id === AccountConstants.name) {
      id = AccountConstants.fullName
    }
    if (id === AccountConstants.status) {
      id = AccountConstants.state
    }
    setOrderBy(id)
    setOrderType(type)
  }

  useEffect(() => {
    const filterData = filter(
      accountData,
      searchItem,
      searchItems,
      permissionSet
    )
    setTotalItems(filterData.length)
    const currentStartIndex = (currentPage - 1) * pageSize
    const startIndex =
      filterData.length > currentStartIndex ? currentStartIndex : 0
    const pageData: Account[] = filterData.slice(
      startIndex,
      startIndex + pageSize
    )
    setAccountPageData(pageData)
    setTimeout(() => {
      handlePageChange(filterData.length > pageSize ? currentPage : 1)
    }, 100)
  }, [
    accounts,
    accountData,
    isLoading,
    currentPage,
    pageSize,
    searchItem,
    searchItems
  ])

  useEffect(() => {
    const getAccountData = createAccountsList(accounts)
    sort(getAccountData, AccountConstants.fullName, AccountConstants.ascending)
    setAccountData(mergeData(getAccountData, accountData))
  }, [accounts, isLoading])

  useEffect(() => {
    setAccountPageData(sort(accountData, orderBy, orderType))
    setPaginationData()
  }, [orderType, orderBy, isLoading, accountData])

  useEffect(() => {
    setPaginationData()
  }, [currentPage, pageSize])

  const setPaginationData = () => {
    const startIndex = (currentPage - 1) * pageSize
    const pageData: Account[] = filter(
      accountData,
      searchItem,
      searchItems,
      permissionSet
    ).slice(startIndex, startIndex + pageSize)
    setAccountPageData(pageData)
  }
  const handleOnColumnReorder = (newOrder) => {
    setOrder(newOrder)
    sessionStorage.setItem(AccountTableColumns, JSON.stringify(newOrder))
  }
  return (
    <>
      <ToastProvider>
        <Table
          data-testid="accounts-data-table"
          className="table-br table-mr accounts-data-table table-scroller"
          columns={accountListColumns}
          columnReorder
          data={accountPageData}
          customNoItems={customNoItems}
          i18n={Retrievei18nItems()}
          onSort={handleSort}
          preferences={{
            ...accountPreferences,
            defaultOrder: defaultOrderColumn,
            order
          }}
          loadingDataLength={4}
          pagination={{
            currentPage: currentPage,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange,
            pageSize: pageSize,
            pageSizeOptions: defaultpageSizeOptions,
            popoverPlacement: 'top-start',
            totalItems
          }}
          onColumnReorder={handleOnColumnReorder}
          component={'table'}
          actionArea={actionArea}
          loading={isLoading}
        />
      </ToastProvider>
    </>
  )
}

export default AccountsList
