import { TablePreferences } from '@veneer/core'

export const basePath = '/my-organization'

export const AccountConstants = {
  name: 'name',
  status: 'status',
  fullName: 'fullName',
  state: 'state',
  ascending: 'ascending',
  pageSize: 25,
  currentPage: 1,
  rowConfig: 'rowConfig'
}

export const defaultpageSizeOptions = [
  { value: 25 },
  { value: 50 },
  { value: 75 },
  { value: 100 }
]

type ColumnIndexTypes = 'visible' | 'hidden' | 'none'

type accountListColumnstype = {
  id: string
  label: string
  index?: ColumnIndexTypes
  sortable?: boolean
}
export const defaultOrderColumn = ['name', 'email', 'tablestatus', 'role']

export const accountListColumns: Array<accountListColumnstype> = [
  {
    id: 'name',
    label: 'User Name'
  },
  {
    id: 'email',
    label: 'Email Address'
  },
  {
    id: 'tablestatus',
    label: 'Status'
  },
  {
    id: 'role',
    label: 'Role'
  },
  // Hiding this table until the API is available
  // {
  //   id: 'assignedto',
  //   label: 'ASSIGNED TO'
  // },
  {
    id: 'uid',
    label: 'uid',
    index: 'hidden'
  }
]
export const accountPreferences: TablePreferences = {
  sortBy: {
    id: AccountConstants.name,
    type: 'ascending'
  },
  width: [
    {
      columnId: 'name',
      width: 50
    },
    {
      columnId: 'email',
      width: 70
    },
    {
      columnId: 'tablestatus',
      width: 30
    },
    {
      columnId: 'role',
      width: 30
    },
    {
      columnId: 'assignedto',
      width: 60
    }
  ]
}

export const LocaleStrings = {
  title: 'title',
  description: 'description',
  detailsIDP: 'tooltip.detailsIDP',
  organizationInfoToolTip: 'tooltip.organizationInfo',
  searchNoResultBlock: 'accounts.searchNoResultBlock',
  noResultFound: 'accounts.noResultFound',
  noProfiles: 'accounts.noProfiles',
  inviteProfiles: 'accounts.inviteProfiles',
  organizationInfo: 'accounts.organizationInfo',
  assignedOrgProfiles: 'accounts.assignedOrgProfiles',
  personalInfo: 'accounts.personalInfo',
  countryRegion: 'accounts.countryRegion',
  phoneNo: 'accounts.phoneNo',
  emailAddress: 'accounts.emailAddress',
  role: 'accounts.role',
  status: 'accounts.status',
  lastUpdated: 'accounts.lastUpdated',
  emptyField: 'accounts.emptyField',
  blankField: 'accounts.blankField',
  authenticationName: 'accounts.authenticationName',
  tenantID: 'accounts.tenantID',
  domain: 'accounts.domain',
  ms: 'accounts.3idOptions.ms',
  hp: 'accounts.3idOptions.hp',
  google: 'accounts.3idOptions.google',
  changesSavedMsg: 'accounts.changesSavedMsg',
  changesNotSavedMsg: 'accounts.changesNotSavedMsg',
  errorLabel: 'accounts.errorLabel',
  tabIDP: 'accounts.tabIDP',
  identityProvider: 'accounts.identityProvider',
  cancel: 'accounts.buttons.cancel',
  leave: 'accounts.buttons.leave',
  save: 'accounts.buttons.save',
  clearAll: 'accounts.buttons.clearAll',
  confirmationMsg: 'accounts.confirmationMsg',
  unsavedChanged: 'accounts.unsavedChanged',
  settingNotSaved: 'accounts.settingNotSaved',
  filter: 'accounts.filter',
  selectFilters: 'accounts.selectFilters',
  searchFilters: 'accounts.searchFilters',
  builtIn: 'accounts.builtIn',
  searchedfilterNotAvailable: 'accounts.searchedfilterNotAvailable',
  itemsSelected: 'accounts.itemsSelected',
  actionCannotBeUndone: 'accounts.actionCannotBeUndone'
}

export const SETTINGS_IDP_WRITE_PERM = [
  { scope: 'ws-hp.com/id-config/config.WRITE' }
]

export const DETAILS_READ_PERM = [
  { scope: 'ws-hp.com/usermgtsvc/usertenantdetails.READ' }
]

export const DETAILS_OWNER_PERSONAL_INFO_PERM = [
  { scope: 'ws-hp.com/usermgtsvc/users.OWNER' }
]

export const DETAILS_READ_ORG_INFO_PERM = [
  { scope: 'ws-hp.com/accountmgtsvc/accounts.READ' }
]

export const STATUS_LABEL_KEY = 'statusLabel'
export const STATUS_VALUE = 'Status'
export const ROLE_LABEL_KEY = 'roleLabel'
export const ROLE_VALUE = 'Role'

export const USER_STATUS_CONSTATNTS = [
  {
    value: 'Active',
    label: 'statusActive'
  },
  {
    value: 'Expired',
    label: 'statusExpired'
  },
  {
    value: 'Pending',
    label: 'statusPending'
  }
]
