/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import {
  ButtonContainer,
  SearchContent,
  ActionAreaFilterTags,
  FormContainer
} from '../styles'
import Accounts from '../Accounts/Accounts'
import FilterSidebar from '../../../Shared-Components/FilterSidebar/FilterSidebar'
import { Account } from '../../../interfaces/accountsDataInterface'
import { getAccounts } from '../../../utils/api'
import {
  Button,
  ContextualMenu,
  IconFunnel,
  IconEllipsis,
  Search,
  Tag
} from '@veneer/core'
import {
  ROLE_LABEL_KEY,
  ROLE_VALUE,
  DETAILS_READ_PERM,
  SETTINGS_IDP_WRITE_PERM,
  STATUS_VALUE,
  STATUS_LABEL_KEY
} from '../../../../src/utils/constants'
import useShellRootContext from '../../../contexts/ShellRoot/useShellRootContext'

const ManageMainAccountsPage = (props) => {
  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const events = interfaces?.v1?.events || props?.mockEvents
  const breadcrumbs = interfaces?.v1?.breadcrumbs || props?.mockBreadcrumbs
  const authStack = interfaces?.v1?.app?.getAuthStack?.() || props?.mockStack
  const orgSelector = interfaces?.v1?.orgSelector || props?.mockOrgSelector
  const authProvider = interfaces?.v2.authProvider || props?.mockAuthProvider
  const accessControl =
    interfaces?.v1?.accessControl || props?.mockAccessControl
  const featureFlags = interfaces?.v1?.featureFlags || props?.mockFeatureFlags
  const permissionSet = props?.properties?.permissionSet

  const [accounts, setAccounts] = useState<Array<Account>>([])
  const [searchItem, setSearchItem] = useState<string>('')
  const [disableFilters, setDisableFilters] = useState<boolean>(false)
  const [showFilterSidebar, setShowFilterSidebar] = useState<boolean>(false)
  const [searchItems, setSearchItems] = useState<string[]>(
    props.mockSearchItems ? props.mockSearchItems : []
  )
  const [searchFlag, setSearchFlag] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [searchValue, setSearchValue] = useState<string>('')
  const [writePermForSettings, setWritePermForSettings] =
    useState<boolean>(false)
  const [showSettingsButtonFlag, setShowSettingsButtonFlag] = useState<boolean>(
    props.mockProp ? true : false
  )
  const [readPermForDetails, setReadPermForDetails] = useState<boolean>(
    props.mockProp ? true : false
  )
  const [hasError, setHasError] = useState<boolean>(false)

  const filterTagKey = (tagKey: string): string => {
    const active = t(
      'accounts-management.accounts.active',
      'Active'
    ).toLowerCase()
    const pending = t(
      'accounts-management.accounts.pending',
      'Pending'
    ).toLowerCase()
    const expired = t(
      'accounts-management.accounts.expired',
      'Expired'
    ).toLowerCase()

    const isStatusKey = () => {
      return (
        tagKey.toLowerCase() === active ||
        tagKey.toLowerCase() === pending ||
        tagKey.toLowerCase() === expired
      )
    }

    const keyMapping = isStatusKey() ? STATUS_LABEL_KEY : ROLE_LABEL_KEY
    const valueMapping = isStatusKey() ? STATUS_VALUE : ROLE_VALUE

    return `${t(`accounts-management.${keyMapping}`, valueMapping)}: ${t(
      `accounts-management.accounts.${tagKey.toLowerCase()}`,
      tagKey
    )}`
  }

  const retriveAccounts = async () => {
    try {
      await accessControl
        .checkScopes(DETAILS_READ_PERM)
        .then((result) => setReadPermForDetails(result))
      setIsLoading(true)
      setDisableFilters(true)
      const resourceId = ''
      const response: Account[] = await getAccounts(
        authStack,
        authProvider,
        orgSelector,
        resourceId,
        permissionSet
      )
      setAccounts(response)
      setIsLoading(false)
      setDisableFilters(false)
      setHasError(false)
    } catch (e) {
      setIsLoading(false)
      setHasError(true)
    }
  }

  const getFeatureFlag = async () => {
    const client = await featureFlags.getClient('ecp')
    const featureFlag = await client.getFeatureFlag({
      key: 'ecp-3id',
      defaultValue: false
    })
    setShowSettingsButtonFlag(featureFlag)
    return featureFlag
  }

  useEffect(() => {
    events.addEventListener('ecp-banner-reload-call', retriveAccounts)
    return () => {
      events.removeEventListener('ecp-banner-reload-call', retriveAccounts)
    }
  }, [events])

  useEffect(() => {
    retriveAccounts()
  }, [])

  useEffect(() => {
    accessControl
      .checkScopes(SETTINGS_IDP_WRITE_PERM)
      .then((result) => setWritePermForSettings(result))
  }, [])

  useEffect(() => {
    getFeatureFlag()
  }, [showSettingsButtonFlag])

  const retriveAccountDetails = async (flag: boolean, referenceId: string) => {
    const response: Account[] = await getAccounts(
      authStack,
      authProvider,
      orgSelector,
      referenceId,
      permissionSet
    )
    props.openDetails(response)
    props.pageTitle('Details')
  }

  useEffect(() => {
    props.pageTitle('Accounts')
    breadcrumbs.remove('details')
    breadcrumbs.remove('settings')
  }, [breadcrumbs])

  const handleUserFilter = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setSearchItem(searchItem)
    setIsLoading(true)
    setSearchItem(searchValue)
    setSearchFlag(!searchFlag)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }

  const handlePerformFilter = (value1: string, value2: string) => {
    value2 === 'push' ? setSearchItems([...searchItems, value1]) : null
    value2 === 'pop'
      ? setSearchItems(searchItems.filter((item) => item !== value1))
      : null
    value2 === 'clearall' ? setSearchItems([]) : null
  }

  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const callback = () => setWindowSize(window.innerWidth)

    useEffect(() => {
      window.addEventListener('resize', callback)
      return () => window.removeEventListener('resize', callback)
    }, [])
    return windowSize
  }

  const resolution = useWindowSize()
  const isMobileView = resolution <= 576

  const mapFilterTags = (searchItems: string[]) => {
    const numberedTagLength = searchItems.length - 3
    const filterTags = searchItems.map((item: string, index: number) => (
      <Tag
        id="proxy-settings-filter-tags"
        data-testid="proxy-settings-filter-tags"
        key={index}
        label={filterTagKey(item)}
        clearButton
        onClick={() => {
          searchItems.splice(index, 1)
          retriveAccounts()
        }}
      />
    ))
    const numberedFilterTag = () => {
      return (
        <Tag
          id="proxy-settings-filter-tags-numbered"
          data-testid="proxy-settings-filter-tags-numbered"
          key="proxy-settings-filter-tags-numbered"
          label={`+${numberedTagLength}`}
          clearButton={false}
        />
      )
    }
    if (searchItems.length <= 3) {
      return filterTags
    } else {
      filterTags.splice(3, numberedTagLength, numberedFilterTag())
      return filterTags
    }
  }

  const searchContent = (
    <SearchContent>
      <FormContainer>
        <form
          id="accounts-search-form"
          data-testid="search-form"
          onSubmit={handleUserFilter}
        >
          <Search
            id="table-name-filter"
            data-testid="search-box"
            placeholder={t('accounts-management.accounts.search', 'Search')}
            className="accountsSearchBox"
            value={searchValue}
            onChange={(value) => setSearchValue(value)}
            onBlur={() => setSearchFlag(!searchFlag)}
            onClear={() => {
              setSearchItem('')
              setSearchFlag(!searchFlag)
            }}
            content={undefined}
            nonce={undefined}
            rel={undefined}
            rev={undefined}
          />
          <Button
            data-testid="table-filter-button"
            className="accounts-filter-button"
            appearance="ghost"
            leadingIcon={<IconFunnel data-testid="icon-funnel" />}
            onClick={() => setShowFilterSidebar(true)}
            disabled={disableFilters}
          >
            {t('accounts-management.accounts.filter', 'Filter')}
          </Button>
        </form>
        {writePermForSettings && showSettingsButtonFlag && (
          <ButtonContainer>
            {!isMobileView ? (
              <Button
                data-testid="setting-button"
                className="accounts-setting-button"
                appearance="secondary"
                onClick={() => props.pageTitle(true)}
              >
                {t('accounts-management.settingsTitle', 'Settings')}
              </Button>
            ) : (
              <ContextualMenu
                placement="bottom"
                onClick={() => props.pageTitle(true)}
                options={[{ value: 0, label: 'Settings' }]}
              >
                <Button
                  appearance="secondary"
                  leadingIcon={<IconEllipsis />}
                />
              </ContextualMenu>
            )}
          </ButtonContainer>
        )}
      </FormContainer>
      {searchItems && (
        <ActionAreaFilterTags>
          {mapFilterTags(searchItems)}
          {searchItems.length > 1 && (
            <Button
              id="clear-all-tags"
              data-testid="clear-all-tags"
              appearance="ghost"
              small
              onClick={() => {
                searchItems.splice(0, searchItems.length)
                retriveAccounts()
              }}
            >
              {t('ecp-fleet-proxy-settings.clearAll', 'Clear All')}
            </Button>
          )}
        </ActionAreaFilterTags>
      )}
    </SearchContent>
  )

  const mainContent = (
    <div>
      <Accounts
        data-testid="accounts-component"
        accounts={accounts}
        searchItem={searchItem}
        actionArea={searchContent}
        openAccountDetails={(value) => retriveAccountDetails(true, value)}
        isLoading={isLoading}
        searchItems={searchItems}
        hasError={hasError}
        readPermForDetails={readPermForDetails}
        permissionSet={permissionSet}
        mockAccountsData={props.mockAccountsData}
      />
      <div
        data-testid="filter-side-bar-div"
        id="filter-side-bar-div"
      >
        {showFilterSidebar && (
          <FilterSidebar
            data-testid="accounts-mngmnt-filter-side-bar"
            onclose={() => setShowFilterSidebar(false)}
            performFilter={(value1: string, value2: string) =>
              handlePerformFilter(value1, value2)
            }
            appliedFilter={searchItems}
            permissionSet={permissionSet}
          />
        )}
      </div>
    </div>
  )

  return mainContent
}

export default ManageMainAccountsPage
