import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: #212121;
  background: #f8f8f8;
  padding: 25px;
  h4 {
    font-size: ${primitives.typography.size4};
    line-height: ${primitives.typography.lineHeight3};
    margin-bottom: 18px;
    flex-grow: 9;
  }
`

export const ToolTipStyle = styled.div`
  position: relative;
  display: inline-flex;
  height: 20px !important;
  width: 20px !important;
  top: 5px;
  margin-left: 6px;
  color: #737373;
  #tooltip {
    width: 250px;
    height: 80px;
    font-size: ${primitives.typography.size1}!important;
    line-height: ${primitives.typography.lineHeight1}!important;
  }
`
export const CardStyle = styled.section`
  padding: 20px 24px 24px 24px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(33, 33, 33, 0.1);
  border-radius: 16px;
  margin-bottom: 24px;
`
export const CardTitle = styled.div`
  font-size: ${primitives.typography.size4};
  line-height: ${primitives.typography.lineHeight4};
  margin-bottom: 15px;
`
export const CardTitleBottomHr = styled.div`
  height: 1px;
  position: relative;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 18px;
  background-color: #ebebeb;
`
export const TextBlockStyle = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`
export const TextItemStyle = styled.div`
  border-left: 3px solid #ebebeb
  padding-left: 15px;
  padding-right: 50px;
  @media (max-width: 576px) {
    margin: 10px 0 0 0;
  }
`

export const TextItemTitle = styled.div`
  display: flex;
  color: #737373;
  font-size: ${primitives.typography.size0};
  line-height: ${primitives.typography.lineHeight1};
`
export const TextItem = styled.div`
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight2};
  svg {
    width: 13.33px;
    height: 13.33px;
    position: relative;
    right: 8.33%;
    top: 8.33%;
    bottom: 8.33%;
    fill: #1c7a17;
  }
  @media (max-width: 576px) {
    svg {
      right: 0;
    }
    padding: 0 0 10px 0;
    display: flex;
    align-items: center;
  }
`
export const RoleTooltip = styled.div`
  flex: 1 !important;
  height: 13.33px !important;
  width: 13.33px !important;
  color: #737373;
  svg {
    height: 16px !important;
  }
  #role-tooltip {
    width: 250px;
    height: 80px;
    font-size: ${primitives.typography.size1}!important;
    line-height: ${primitives.typography.lineHeight1}!important;
  }
`
export const BasicTextItemStyle = styled.div`
  display: block;
  margin-bottom: 20px;
`
export const PersonalInformationCountryContentStyle = styled.div`
  line-height: ${primitives.typography.lineHeight2};
  font-size: ${primitives.typography.size2};
`
export const BasicTextItemTitle = styled.div`
  color: #737373;
  font-size: ${primitives.typography.size0};
  line-height: ${primitives.typography.lineHeight1};
`
export const BasicTextItem = styled.div`
  font-size: ${primitives.typography.size1};
  line-height: ${primitives.typography.lineHeight2};
  @media (max-width: 576px) {
    word-break: break-word;
  }
`
export const TitleBoxStyle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${primitives.typography.size4};
  line-height: ${primitives.typography.lineHeight2};
  text-transform: capitalize;
  h4 {
    font-size: ${primitives.typography.size4} !important;
    line-height: ${primitives.typography.lineHeight4} !important;
    margin-top: 2px;
    flex-grow: 9;
    color: #212121;
  }
  svg {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    position: relative;
    vertical-align: middle;
    color: #027aae
    margin-top: 2px;
  }
`
export const ActionButtonsStyle = styled.div`
  flex-grow: 1;
  text-align: right;
  margin-top: -5px;
  button {
    height: 36px;
    margin: 0;
  }
`
export const TitleCardStyle = styled.section`
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(33, 33, 33, 0.1);
  border-radius: ${primitives.layout.cornerRadius4}px;
  margin-bottom: ${primitives.layout.size7}px !important;
  :first-child,
  .vn-animated-height__wrapper-inner > div {
    padding: 10px;
    margin: 0;
    color: #212121;
  }
  div[role='button'] {
    padding: 14px ${primitives.layout.size5}px;
    height: 25px;
  }
`
export const ReloadBoxStyle = styled.div`
  text-align: right;
  margin-bottom: ${primitives.layout.size6}px !important;
  padding-right: ${primitives.layout.size1}px;
  button {
    color: #027aae
    margin-right: ${primitives.layout.size1}px;
  }
`

export const RelaodCaption = styled.span`
  width: 227px !important;
  height: 20px !important;
  color: #404040;
  font-size: ${primitives.typography.size1};
  line-height: ${primitives.typography.lineHeight2} !important;
`
