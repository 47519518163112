import {
  Account,
  PermissionSetProps
} from 'src/interfaces/accountsDataInterface'
import { USER_STATUS_CONSTATNTS } from './constants'

export const mergeData = (parentdata, childdata) => {
  return parentdata.map(
    (parentitem) =>
      childdata.find((item) => item.resourceId === parentitem.resourceId) ||
      parentitem
  )
}
export const filter = (
  userListData: Array<Account>,
  searchItem: string,
  searchItems: string[],
  permissionSet: Array<PermissionSetProps>
) => {
  const permSetValues = permissionSet?.map((item) => item?.label?.toLowerCase())
  const StatusValues = USER_STATUS_CONSTATNTS.map((item) =>
    item.value.toLowerCase()
  )
  if (searchItem.length === 0 && searchItems.length === 0) {
    return userListData
  }
  const filterdata = userListData
    .filter((account) => {
      if (searchItem.length != 0) {
        return Object.values(account)
          .join(' ')
          .toLowerCase()
          .includes(searchItem.toLowerCase())
      } else {
        return account
      }
    })
    .filter((user) => {
      /* istanbul ignore else */
      if (searchItems.length != 0) {
        if (searchItems.some((item) => permSetValues.includes(item))) {
          if (searchItems.some((item) => StatusValues.includes(item))) {
            return (
              searchItems.some((value) =>
                user.state.toLowerCase().includes(value)
              ) &&
              searchItems.some(
                (value) => user.roleLabel.toLowerCase() === value.toLowerCase()
              )
            )
          } else {
            return searchItems.some(
              (value) => user.roleLabel.toLowerCase() === value.toLowerCase()
            )
          }
        } else {
          return searchItems.some((value) =>
            user.state.toLowerCase().includes(value)
          )
        }
      } else {
        return user
      }
    })

  return filterdata
}
export const sort = <Type>(
  data: Array<Type>,
  orderBy: string,
  orderType: string
) => {
  return data.sort((obj1, obj2) => {
    const value =
      orderType === 'descending'
        ? descendingComparator(obj1, obj2, orderBy)
        : -descendingComparator(obj1, obj2, orderBy)

    return value
  })
}

export const descendingComparator = <Type>(
  obj1: Type,
  obj2: Type,
  orderBy: string
) => {
  const obj1Sort = obj1[orderBy] && obj1[orderBy].toString().toUpperCase()
  const obj2Sort = obj2[orderBy] && obj2[orderBy].toString().toUpperCase()
  if (obj2Sort < obj1Sort) {
    return -1
  }
  return obj2Sort > obj1Sort ? 1 : 0
}

export const jsonParse = (text: string, defResult = []) => {
  try {
    return text ? JSON.parse(text) : defResult
  } catch (SyntaxError) {
    return defResult
  }
}
