const baseL10nMap = {
  Active: 'accounts-management.accounts.active',
  Expired: 'accounts-management.accounts.expired',
  Pending: 'accounts-management.accounts.pending'
}

let l10nMap = { ...baseL10nMap }

export const mergel10nMap = (map = {}) => {
  l10nMap = {
    ...baseL10nMap,
    ...map
  }
}

export const getl10n = (key) => l10nMap[key] || key
