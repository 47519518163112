import React, { useCallback } from 'react'
import { RmcMfeLoader } from '@jarvis/react-mfe-component'

export const HeaderBanner = ({ title }, ...props) => {
  const SubMfe = useCallback(
    ({ component, title, type }) => {
      return (
        <RmcMfeLoader
          disableContainerPadding
          {...props}
          {...{
            component,
            title,
            type
          }}
        />
      )
    },
    [props]
  )
  return (
    <SubMfe
      type="headerTitle"
      title={title}
      component="@jarvis/react-ecp-header"
    />
  )
}
