import React from 'react'
import { ProgressIndicator } from '@veneer/core'

const Loader = () => {
  return (
    <div style={{ margin: '10px 0' }}>
      <ProgressIndicator
        appearance="circular"
        behavior="indeterminate"
      />
    </div>
  )
}

export default Loader
