import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: #000000;
  background: #f8f8f8;
  h4 {
    font-size: 28px;
    line-height: 36px;
    color: #212121;
  }
  .accountName {
    cursor: pointer;
  }
  .accounts-icon-reload {
    fill: rgb(2, 122, 174) !important;
  }
  >div: first-child {
    padding-bottom: ${primitives.layout.size2}px;
  }
  .table-br > div > button {
    color: #027aae;
    width: 36px;
    height: 36px;
  }
  .table-scrollerdiv: nth-child(2) > table {
    @media (max-width: 768px) {
      overflow: scroll;
      width: 900px;
    }
  }
  div[dir='ltr']: first-child {
    padding-top: 20px;
  }
  div [dir='ltr'] {
    float: left;
    width: 20vw;
    padding: 20px 0px 0px 24px !important;
  }
  div [dir='ltr'] > div:first-child > div:first-child {
    font-size: 14px !important;
    line-height: ${primitives.typography.lineHeight3} !important;
  }
  .accounts-data-table {
    min-height: 40vh;
    td {
      overflow: visible;
      height: 44px !important;
      line-height: ${primitives.typography.lineHeight2};
    }
    > div {
      > div {
        margin: 0;
      }
    }
    button[aria-label='Select page size'] {
      width: 75px;
      height: 32px;
      line-height: ${primitives.typography.lineHeight2};
    }
    span {
      line-height: ${primitives.typography.lineHeight2};
    }
  }
  .footer-contextualmenu {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: block;
    margin: 0;
    height: 64px;
    right: 0;
    z-index: 3;
    animation: 1s ease 0s 1 normal none running animatebottom;
    li {
      -webkit-box-align: center !important;
      align-items: center;
      border-color: transparent;
      border-radius: 0px;
      border-style: solid;
      border-width: 0px 1px;
      box-sizing: border-box;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      padding: 14px ${primitives.layout.size6}px;
      padding-left: 284px;
      position: relative;
      width: 100%;
      background: #ebf9ff;
    }
  }
  .left-cancel-button-box {
    display: inline-flex;
    padding-right: ${primitives.layout.size5}px;
    button {
      height: 36px;
      width: 104px;
      max-width: 104px;
      min-width: 104px;
    }
  }
  .items-selected-box {
    display: inline-flex;
    flex: 1 1 100%;
    order: 1;
    font-size: ${primitives.typography.size2};
    line-height: ${primitives.typography.lineHeight3};
    vertical-align: middle;
  }
  .right-action-box {
    display: inline-flex;
    flex: 0 0 auto;
    order: 2;
    height: 36px;
    .removeUserSelectBox {
      div {
        height: 36px;
      }
    }
    #deleteBtn {
      height: 36px;
      width: 123px;
      max-width: 123px;
      min-width: 123px;
    }
    #select-footer-batch-operation-label {
      font-size: 10px;
      line-height: 12px;
    }
    #select-footer-batch-operation {
      padding-top: 27px;
      span {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .right-action-flex-box {
    display: flex;
    div {
      padding-right: 10px;
      div > div {
        padding-right: 16px;
        div + input + span {
          padding-right: 0px;
          div.vn-select__open-button {
            padding-right: 0px;
            height: 24px;
          }
        }
      }
    }
  }
  .right-action-select-box {
    min-width: 224px;
    max-width: 320px;
    height: 36px;
  }
`
export const AccountStatus = styled.div`
  display: flex;
  .active {
    width: ${primitives.layout.size3}px;
    height: ${primitives.layout.size3}px;
    background: #309f2f;
    border-radius: 80%;
    margin: 5px ${primitives.layout.size2}px 0 2px;
  }
  .active1 {
    width: ${primitives.layout.size4}px;
    height: ${primitives.layout.size4}px;
    border-radius: 80%;
    margin: 5px 0 0 2px;
  }
  .expired {
    width: ${primitives.layout.size3}px;
    height: ${primitives.layout.size3}px;
    background: #be1313;
    border-radius: 80%;
    margin: 5px ${primitives.layout.size2}px 0 2px;
  }
  .expired1 {
    width: ${primitives.layout.size4}px;
    height: ${primitives.layout.size4}px;
    border-radius: 80%;
    margin: 5px 0 0 2px;
  }
  .pending {
    width: ${primitives.layout.size3}px;
    height: ${primitives.layout.size3}px;
    background: #adadad;
    border-radius: 80%;
    margin: 5px ${primitives.layout.size2}px 0 2px;
  }
  .pending1 {
    width: ${primitives.layout.size4}px;
    height: ${primitives.layout.size4}px;
    border-radius: 80%;
    margin: 5px 0 0 2px;
  }
  .accounts-user-state {
    text-transform: capitalize;
    font-size: ${primitives.typography.size2};
  }
`
export const NavigationTabsWrapper = styled.div`
  margin-bottom: 0 !important;
  &-wrapper {
    margin: 15px 0 5px 0;

    > div > div {
      font-size: 16px;
      line-height: 20px;
      color: #404040;
    }
  }
`
export const ReloadSection = styled.div`
  float: right;
  margin-top: -76px;
  margin-bottom: 0 !important;
  button {
    margin-right: ${primitives.layout.size1}px;
  }
`
export const ReloadBoxStyle = styled.div`
  text-align: right;
  margin-bottom: ${primitives.layout.size6}px !important;
  padding-right: ${primitives.layout.size1}px;
  button {
    color: #027aae;
    margin-right: ${primitives.layout.size1}px;
  }
`

export const RelaodCaption = styled.span`
  width: 227px !important;
  height: 20px !important;
  color: #404040;
  font-size: ${primitives.typography.size1};
  line-height: ${primitives.typography.lineHeight2} !important;
`
export const SearchContent = styled.div`
  width: 100%;
  padding: 0px 0 12px;

  display: flex;
  flex-direction: column;

  label {
    line-height: ${primitives.typography.lineHeight1};
  }

  #accounts-search-form {
    flex-grow: 9;
    .accountsSearchBox {
      width: 276px;
      float: left;
      .vn-input {
        height: 36px;
        min-height: 36px;
        max-height: 36px;
      }
      div > div {
        border-color: #adadad !important;
      }
      label {
        color: #737373;
      }
      @media (max-width: 496px) {
        width: 145px;
      }
      @media (max-width: 320px) {
        width: 30px;
        .vn-input input {
          display: none;
        }
        .vn-input:hover {
          background: transparent;
        }
        div {
          border: none;
        }
      }
    }
    .searchBox-input_container {
      float: left;
      .vn-input {
        height: ${primitives.typography.lineHeight6};
        width: 258px;
      }
      #table-name-filter {
        padding: 8px 12px;
        height: ${primitives.typography.lineHeight6};
      }
    }
    .accounts-filter-button {
      margin-left: ${primitives.layout.size3}px;
      @media (max-width: 365px) {
        max-width: 48px;
        min-width: 0;
        span:nth-child(1) {
          margin: 0;
        }
        span:nth-child(2) {
          display: none;
        }
      }
    }
  }
`
export const FormContainer = styled.div`
  display: flex;
`
export const ButtonContainer = styled.div`
  flex-grow: 0;
  .accounts-setting-button {
    order: 1;
    height: 36px;
    border-radius: ${primitives.layout.cornerRadius2}px;
  }
`
export const ViewDetailsMask = styled.div`
  position: absolute;
  width: 97.8%;
  z-index: 1;
  margin-top: -10px;
  cursor: pointer;
  height: 85%;
  td {
    padding: 20px !important;
  }
`
export const AccountName = styled.div`
  cursor: pointer;
  color: #027aae;
  text-transform: capitalize;
`
export const InviteProfile = styled.div`
  color: #404040;
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight3};
`
export const NoITemsContainer = styled.div`
  > div:first-child > div:first-child {
    margin-bottom: ${primitives.layout.size2}px;
  }
`
export const VeneerSpace2 = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: ${primitives.layout.size2}px;
`
export const ActionAreaFilterTags = styled.div`
  display: flex;
  width: 100%;
  padding-top: ${primitives.layout.size2}px;
`
