import React, { useEffect, useState } from 'react'
import { Container } from './styles'
import AccountDetails from '../ManageAccounts/AccountDetails'
import MainAccountPage from '../ManageAccounts/MainAccountPage'
import { HeaderBanner } from '../../Shared-Components/Header/index'
import { ThirdPartyIDPSettings } from '../../../src/Shared-Components/ThirdPartyIDPSettings/index'
import useShellRootContext from '../../contexts/ShellRoot/useShellRootContext'
import { Account } from '../../interfaces/accountsDataInterface'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { getRolePermID } from '../../utils/api'
import {
  DETAILS_OWNER_PERSONAL_INFO_PERM,
  DETAILS_READ_ORG_INFO_PERM,
  basePath
} from '../../../src/utils/constants'
import accountsData from '../../mocks/mock-account.json'
import { JarvisPortalTemplate } from '@jarvis/react-portal-additions/dist/index'
import { AppPropsType } from 'src/App/types'

const ManageAccounts = (props: AppPropsType) => {
  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const navigation = interfaces?.v1?.navigation || props.mockNavigation
  const accessControl = interfaces?.v1?.accessControl || props.mockAccessControl
  const authStack = interfaces?.v1?.app?.getAuthStack?.() || props.mockStack
  const authProvider = interfaces?.v2.authProvider || props.mockAuthProvider
  const breadcrumbs = interfaces?.v1?.breadcrumbs || props.mockBreadcrumbs
  const [organizationInfo, setOrganizationInfo] = useState<string>('')
  const [title, setTitle] = useState<string>(
    t('accounts-management.title', 'My Organization')
  )
  const [viewAccount, setViewAccount] = useState<Account[]>([accountsData])
  const [ownerPermForPersonalInfo, setOwnerPermForPersonalInfo] =
    useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)

  const [writePermForOrgInfo, setWritePermForOrgInfo] = useState<boolean>(false)

  const getOrgDetails = async () => {
    const activeUserDetails = await getRolePermID(authStack, authProvider)
    const orgDetails = activeUserDetails?.data?.tenantResourceId
    setOrganizationInfo(orgDetails)
  }

  useEffect(() => {
    getOrgDetails()
    navigation.push(basePath)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    accessControl
      .checkScopes(DETAILS_OWNER_PERSONAL_INFO_PERM)
      ?.then((result) => setOwnerPermForPersonalInfo(result))
    accessControl
      .checkScopes(DETAILS_READ_ORG_INFO_PERM)
      ?.then((result) => setWritePermForOrgInfo(result))
  })

  const handlePageTitle = (value) => {
    value === 'Accounts'
      ? accountTitle()
      : value === 'Details'
        ? detailsPage()
        : settingsButton()
  }

  const settingsButton = () => {
    setTitle(t('accounts-management.settingsTitle', 'Settings'))
    navigation.push('/my-organization/settings')
    breadcrumbs.add({
      key: 'settings',
      text: `${t('accounts-management.settingsTitle', 'Settings')} : ${t(
        'accounts-management.accounts.tabIDP',
        'IDP'
      )}`,
      translationKey: 'string',
      url: undefined
    })
  }

  const detailsPage = () => {
    setTitle('')
    navigation.push('/my-organization/details')
    breadcrumbs.add({
      key: 'details',
      text: `${t('accounts-management.accounts.detials', 'Details')}`,
      translationKey: 'string',
      url: undefined
    })
  }

  const accountTitle = () => {
    setTitle(t('accounts-management.title', 'My Organization'))
  }

  const accountDetailsPage = (value) => {
    setViewAccount(value)
    setHasError(false)
  }

  const getContent = () => {
    return (
      <>
        <Router>
          <Switch>
            <Route path="*/my-organization/settings">
              <ThirdPartyIDPSettings
                {...props}
                navigation={navigation}
                nodeId={organizationInfo}
                basePath={basePath}
              />
            </Route>
            <Route path="*/my-organization/details">
              <AccountDetails
                {...props}
                selectedAccount={viewAccount}
                ownerPermForPersonalInfo={ownerPermForPersonalInfo}
                writePermForOrgInfo={writePermForOrgInfo}
              />
            </Route>
            <Route path="*/my-organization">
              <MainAccountPage
                {...props}
                pageTitle={(value) => handlePageTitle(value)}
                openDetails={(value) => accountDetailsPage(value)}
                hasError={hasError}
              />
            </Route>
          </Switch>
        </Router>
      </>
    )
  }

  return (
    <>
      <HeaderBanner
        {...props}
        title={title}
      />
      <Container>
        <JarvisPortalTemplate
          data-testid="jarvis-portal-template"
          direction="ltr"
          fullWidth
          mainContent={getContent()}
        />
      </Container>
    </>
  )
}

export default ManageAccounts
