import React, { useEffect, useState, useCallback } from 'react'
import {
  BasicTextItem,
  BasicTextItemStyle,
  BasicTextItemTitle,
  CardStyle,
  CardTitle,
  CardTitleBottomHr,
  TextBlockStyle,
  TextItem,
  TextItemStyle,
  TextItemTitle,
  TitleBoxStyle,
  TitleCardStyle,
  ToolTipStyle,
  RoleTooltip,
  PersonalInformationCountryContentStyle
} from '../AccountDetails/styles'
import { getl10n } from '../../../utils/localizationMap'
import { Account } from 'src/interfaces/accountsDataInterface'
import { IconCheckmarkCircle, IconInfo, Accordion, Tooltip } from '@veneer/core'
import { AccountStatus } from '../styles'
import { getOrganizationProfile, getUserPersonalInfo } from '../../../utils/api'
import Loader from '../../../Shared-Components/Loader'
import useShellRootContext from '../../../contexts/ShellRoot/useShellRootContext'

const AccountDetails = ({
  selectedAccount,
  ownerPermForPersonalInfo,
  writePermForOrgInfo,
  mockEvents,
  mockOrgSelector,
  mockStack,
  mockAuthProvider
}: AccountDetailsProps) => {
  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const events = interfaces?.v1?.events || mockEvents
  const authStack = interfaces?.v1?.app?.getAuthStack?.() || mockStack
  const authProvider = interfaces?.v2?.authProvider || mockAuthProvider
  const orgSelector = interfaces?.v1?.orgSelector || mockOrgSelector

  const [orgProfileCount, setOrgProfileCount] = useState<number>(0)
  const [orgProfileData, setOrgProfileData] = useState<string>('')
  const [showOrgProfileLoader, setShowOrgProfileLoader] =
    useState<boolean>(false)
  const [showPersonalInfoLoader, setShowPersonalInfoLoader] =
    useState<boolean>(false)
  const [countryInfo, setCountryInfo] = useState<string>('')
  const [phoneInfo, setPhoneInfo] = useState<string>('')

  const getOrganizationProfileInfo = useCallback(async () => {
    setShowOrgProfileLoader(true)
    const organizationInfo = await getOrganizationProfile(
      authStack,
      authProvider,
      orgSelector,
      ''
    )
    const response_array = organizationInfo?.data?.resourceList
    setOrgProfileCount(organizationInfo?.data?.totalCount)
    let orgData = ''
    response_array.forEach((item) => {
      orgData += `${item?.user?.givenName} ${item?.user?.familyName}(${item?.user?.email?.value}), `
    })
    const orgDataLen = orgData?.length
    setOrgProfileData(orgData?.substring(0, orgDataLen - 2))
    setShowOrgProfileLoader(false)
  }, [authProvider, authStack, orgSelector])

  const getPersonalInfo = useCallback(async () => {
    setShowPersonalInfoLoader(true)
    const personalInfo = await getUserPersonalInfo(
      authStack,
      authProvider,
      selectedAccount[0].resourceId
    )
    setCountryInfo(personalInfo.data.country)
    setPhoneInfo(personalInfo.data.phoneNumber?.value)
    setShowPersonalInfoLoader(false)
  }, [authProvider, authStack, selectedAccount])

  const loadData = useCallback(() => {
    getPersonalInfo()
    getOrganizationProfileInfo()
  }, [getPersonalInfo, getOrganizationProfileInfo])

  useEffect(() => {
    events?.addEventListener('ecp-banner-reload-call', loadData)
    return () => {
      events?.removeEventListener('ecp-banner-reload-call', loadData)
    }
  }, [events, loadData])

  useEffect(() => {
    loadData()
  }, [loadData])

  const renderAccountInfo = () => {
    return (
      <TitleBoxStyle>
        <h4 data-testid="account-detail-title">
          {`${selectedAccount[0].givenName} ${selectedAccount[0].familyName}`}
        </h4>
      </TitleBoxStyle>
    )
  }
  const renderAccountInfoHeader = () => {
    return (
      <>
        <TextBlockStyle>
          <TextItemStyle>
            <TextItemTitle data-testid="status-title">
              {t('accounts-management.accounts.status', 'Status')}
            </TextItemTitle>
            <TextItem>
              <AccountStatus data-testid="account-status">
                <IconCheckmarkCircle
                  className={selectedAccount[0].state.toLowerCase() + '1'}
                  filled
                />
                <span className="accounts-user-state">
                  {t(
                    getl10n(selectedAccount[0].state),
                    selectedAccount[0].state
                  )}
                </span>
              </AccountStatus>
            </TextItem>
          </TextItemStyle>
          <TextItemStyle>
            <TextItemTitle>
              {t('accounts-management.accounts.role', 'ROLE')}
              <RoleTooltip>
                <Tooltip
                  content={
                    <>
                      {t(
                        'accounts-management.tooltip.roleTooltip',
                        'Role is assigned to all users, and permissions are accessed based on the assigned roles.'
                      )}
                    </>
                  }
                  arrow
                  id="role-tooltip"
                  placement="trailing"
                >
                  <IconInfo />
                </Tooltip>
              </RoleTooltip>
            </TextItemTitle>
            <TextItem>{selectedAccount[0].roleLabel}</TextItem>
          </TextItemStyle>
          <TextItemStyle>
            <TextItemTitle>
              {t('accounts-management.accounts.emailAddress', 'EMAIL ADDRESS')}
            </TextItemTitle>
            <TextItem>{selectedAccount[0].email}</TextItem>
          </TextItemStyle>
        </TextBlockStyle>
      </>
    )
  }
  const dataInitialis = [
    {
      content: renderAccountInfoHeader(),
      expanded: true,
      header: {
        centralArea: renderAccountInfo()
      }
    }
  ]
  const [data, setData] = useState(dataInitialis)
  const [hasEffect, setHasEffect] = useState<boolean>(false)
  const handleExpand = () => {
    const updatedData = [...dataInitialis]
    updatedData[0].expanded = true
    setData(updatedData)
    setHasEffect(true)
  }

  const handleCollapse = () => {
    const updatedData = [...dataInitialis]
    updatedData[0].expanded = false
    setData(updatedData)
    setHasEffect(true)
  }
  const renderAccountHeader = () => {
    return (
      <TitleCardStyle>
        <Accordion
          items={hasEffect ? data : dataInitialis}
          onCollapse={handleCollapse}
          onExpand={handleExpand}
          data-testid="header-accordion"
        />
      </TitleCardStyle>
    )
  }

  const renderPersonalInfo = () => {
    return (
      <CardStyle>
        <CardTitle>
          {t(
            'accounts-management.accounts.personalInfo',
            'Personal Information'
          )}
        </CardTitle>
        <CardTitleBottomHr></CardTitleBottomHr>
        <BasicTextItemStyle>
          <BasicTextItem>
            {t('accounts-management.accounts.countryRegion', 'Country/Region')}
          </BasicTextItem>
          {showPersonalInfoLoader ? (
            <BasicTextItem data-testid="account-detail-country">
              <Loader />
            </BasicTextItem>
          ) : (
            <PersonalInformationCountryContentStyle data-testid="account-detail-country">
              {countryInfo ? countryInfo : '--'}
            </PersonalInformationCountryContentStyle>
          )}
        </BasicTextItemStyle>
        <BasicTextItemStyle>
          <BasicTextItemTitle>
            {t('accounts-management.accounts.phoneNo', 'Work Phone Number')}
          </BasicTextItemTitle>
          {showPersonalInfoLoader ? (
            <BasicTextItem data-testid="account-detail-phone">
              <Loader />
            </BasicTextItem>
          ) : (
            <BasicTextItem data-testid="account-detail-phone">
              {phoneInfo ? phoneInfo : '--'}
            </BasicTextItem>
          )}
        </BasicTextItemStyle>
      </CardStyle>
    )
  }
  const renderOrgInfo = () => {
    return (
      <CardStyle>
        <CardTitle>
          {t(
            'accounts-management.accounts.organizationInfo',
            'Organization Information'
          )}
          <ToolTipStyle>
            <Tooltip
              content={
                <>
                  {t(
                    'accounts-management.tooltip.organizationInfoTooltip',
                    'Organizational Information provides assigned customer to be managed and country regional information.'
                  )}
                </>
              }
              arrow
              id="tooltip"
              placement="trailing"
            >
              <IconInfo />
            </Tooltip>
          </ToolTipStyle>
        </CardTitle>
        <CardTitleBottomHr></CardTitleBottomHr>
        <BasicTextItemStyle>
          <BasicTextItemTitle>
            {t(
              'accounts-management.accounts.assignedOrgProfiles',
              'Assigned Organization Profiles'
            )}
            ({orgProfileCount})
          </BasicTextItemTitle>
          {showOrgProfileLoader ? (
            <BasicTextItem data-testid="account-detail-organization-value">
              <Loader />
            </BasicTextItem>
          ) : (
            <BasicTextItem data-testid="account-detail-organization-value">
              {orgProfileData}
            </BasicTextItem>
          )}
        </BasicTextItemStyle>
        <BasicTextItemStyle>
          <BasicTextItemTitle>
            {t('accounts-management.accounts.countryRegion', 'Country/Region')}
          </BasicTextItemTitle>
          <BasicTextItem>{`--`}</BasicTextItem>
        </BasicTextItemStyle>
      </CardStyle>
    )
  }
  const renderAccountDetails = () => {
    return (
      <>
        {renderAccountHeader()}
        {ownerPermForPersonalInfo ? renderPersonalInfo() : null}
        {writePermForOrgInfo ? renderOrgInfo() : null}
      </>
    )
  }
  return renderAccountDetails()
}
export interface AccountDetailsProps {
  selectedAccount: Account[]
  ownerPermForPersonalInfo: boolean
  writePermForOrgInfo: boolean
  mockEvents?: {
    addEventListener: (e: string, f: () => void) => void
    removeEventListener: (e: string, f: () => void) => void
  }
  mockOrgSelector?: {
    getOrgTenantId: () => string
  }
  mockStack?: number
  mockAuthProvider?: { createOrgedAuthProvider: () => string }
}
export default AccountDetails
