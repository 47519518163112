import React, { useCallback } from 'react'
import { RmcMfeLoader } from '@jarvis/react-mfe-component'

export const ThirdPartyIDPSettings = ({
  navigation,
  nodeId,
  basePath,
  ...props
}) => {
  const SubMfe = useCallback(
    ({ component, navigation, nodeId, basePath, type }) => {
      return (
        <RmcMfeLoader
          disableContainerPadding
          {...props}
          {...{
            component,
            navigation,
            nodeId,
            basePath,
            type
          }}
        />
      )
    },
    [props]
  )

  return (
    <SubMfe
      type="third-party-idp-settings"
      props={props}
      navigation={navigation}
      nodeId={nodeId}
      basePath={basePath}
      component="@jarvis/react-ecp-third-party-idp-settings"
    />
  )
}
